<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form :inline="true" label-position="right" :model="formInline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Induced_screen_name')">
                <el-input
                  v-model="formInline.screenName"
                  placeholder="请输入诱导屏名称"
                  @keyup.enter.native.prevent="
                    page = 1;
                    getScreenList();
                  "
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.type')">
                <el-select v-model.trim="formInline.category" size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="一级" value="1"></el-option>
                  <el-option label="二级" value="2"></el-option>
                  <el-option label="三级" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.state" size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="在线" value="1"></el-option>
                  <el-option label="离线" value="0"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                style="margin-right: 5px"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  page = 1;
                  getScreenList();
                "
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="
              $route.meta.authority.button.add ||
              $route.meta.authority.button.open ||
              $route.meta.authority.button.close ||
              $route.meta.authority.button.batchaddPrm
            "
          ></div>
          <div
            class="col_box h44"
            v-if="
              $route.meta.authority.button.add ||
              $route.meta.authority.button.open ||
              $route.meta.authority.button.close ||
              $route.meta.authority.button.batchaddPrm
            "
          >
            <div class="col_left">
              <router-link :to="{ path: '/editScreenDetail' }"
                ><el-button
                  type="primary"
                  icon="el-icon-plus"
                  :loading="loading"
                  v-if="$route.meta.authority.button.add"
                  >{{ $t('button.addto') }}</el-button
                ></router-link
              >
              <el-button
                type="primary"
                icon="el-icon-unlock"
                class="el-button--success"
                @click="getOperationScreen(1)"
                v-if="$route.meta.authority.button.open"
                >开屏</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-lock"
                class="el-button--danger"
                @click="getOperationScreen(2)"
                v-if="$route.meta.authority.button.close"
                >关屏</el-button
              >
              <el-button
                type="info"
                icon="el-icon-circle-plus"
                @click="addMore()"
                v-if="$route.meta.authority.button.batchaddPrm"
                >批量{{ $t('button.addto') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        border
        :data="tableData"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
          v-for="item in tableCols"
          :key="item.prop"
          :sortable="item.sortable"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <AuthorityComponent
              ComponentName="el-dropdown"
              align="center"
              @command="handleCommand($event, scope.row)"
              width="80"
            >
              <el-button type="text" size="small" style="padding: 0"
                >操作<i class="el-icon-arrow-down"
              /></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a" v-if="$route.meta.authority.button.addPrm"
                  >添加节目</el-dropdown-item
                >
                <el-dropdown-item
                  command="c"
                  v-if="$route.meta.authority.button.send && scope.row.programStatus == 2"
                  >重新发送</el-dropdown-item
                >
                <el-dropdown-item command="b" v-if="$route.meta.authority.button.edit"
                  >编辑</el-dropdown-item
                >
              </el-dropdown-menu>
            </AuthorityComponent>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        class="dialogVisible"
        title="添加播放节目（最多可添加一个节目）"
        :visible.sync="dialogVisible"
        width="800px"
      >
        <el-form :data="programList" @selection-change="handleSelectionChange">
          <el-form-item label="" prop="selectValue">
            <el-transfer
              target-order="push"
              filterable
              :filter-method="filterMethod"
              filter-placeholder="请输入名称"
              :titles="['全部节目', '已选节目']"
              v-model="selectValue"
              :data="programList"
            >
            </el-transfer>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancle">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  exportExcelNew,
  dateFormat,
  //   setIndex
} from "@/common/js/public.js";

export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      selectValue: [],
      programList: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      loading: false,
      dialogVisible: false,
      formInline: {
        screenName: "",
        category: "",
        state: "",
      },
      tableCols: [
        {
          prop: "screenName",
          label: "诱导屏名称",
        },
        {
          prop: "materialType",
          label: "分辨率",
          formatter: function (row) {
            return row.screenWidth + "*" + row.screenHeight;
          },
        },
        {
          prop: "sn",
          label: "SN",
        },
        {
          prop: "category",
          label: "诱导屏类型",
          formatter: function (row) {
            if (row.category == 1) {
              return "一级";
            } else if (row.category == 2) {
              return "二级";
            } else if (row.category == 3) {
              return "三级";
            } else {
              return "";
            }
          },
        },
        {
          prop: "location",
          label: "诱导屏位置",
        },
        {
          prop: "state",
          label: "诱导屏状态",
          formatter: function (row) {
            if (row.state == 0) {
              return "离线";
            } else if (row.state == 1) {
              return "在线";
            } else {
              return "";
            }
          },
        },
        {
          prop: "programConent",
          label: "当前播放",
          formatter: function (row) {
            if (!row.programConent) {
              return "无";
            } else {
              return row.programConent;
            }
          },
        },
        {
          prop: "programStatus",
          label: "节目状态",
          formatter: function (row) {
            if (row.programStatus == 0) {
              return "发送中";
            } else if (row.programStatus == 1) {
              return "发送成功";
            } else if (row.programStatus == 2) {
              return "发送失败";
            }
          },
        },
      ],
      tableData: [],
      multipleSelection: [],
      screenId: "",
      screenList: [],
      screenStateList: [],
    };
  },
  watch: {},
  methods: {
    cancle() {
      this.dialogVisible = false;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.screenList = [];
        this.dialogVisible = true;
        this.screenId = data.screenId;
        let screen = {};
        screen.screenId = data.screenId;
        screen.screenName = data.screenName;
        this.screenList.push(screen);
        this.getProgramList();
        this.getShowProgramList();
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/editScreenDetail",
          query: data,
        });
      }
      if (cmd == "c") {
        this.$axios
          .post("/acb/2.0/ydpScreen/resendProgram", {
            data: {
              screenId: data.screenId,
              screenCode: data.screenCode,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.getScreenList();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.screenList = [];
      this.screenStateList = [];
      for (var i = 0; i < val.length; i++) {
        let screen = {};
        screen.screenId = val[i].screenId;
        screen.screenName = val[i].screenName;
        this.screenStateList.push(val[i].screenCode);
        this.screenList.push(screen);
      }
    },
    // 诱导屏开屏关屏
    getOperationScreen(n) {
      if (this.multipleSelection == "") {
        this.$message({
          type: "error",
          message: "请选择需要" + (n == 1 ? "开屏" : "关屏") + "的节目",
        });
      } else {
        this.$axios
          .post("/acb/2.0/ydpScreen/operationScreen", {
            data: {
              operationType: n,
              ydpScreenCodes: this.screenStateList.join(","),
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.equipmentModel = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
    addMore() {
      if (this.multipleSelection == "") {
        this.$message({
          type: "error",
          message: "请选择需要添加节目的列表！",
        });
      } else {
        //                    this.screenList = [];
        this.dialogVisible = true;
        this.getProgramList();
      }
    },
    handleAdd(data) {
      this.$router.push({
        path: "/addProgram",
        query: data,
      });
    },
    // 诱导屏列表
    getScreenList(data) {
      this.$axios
        .get("/acb/2.0/ydpScreen/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = Number(res.value.total);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 添加节目列表
    getProgramList(e) {
      //                this.screenList = []
      this.programList = [];
      this.$axios.get("/acb/2.0/ydpProgram/open/list", {}).then((res) => {
        if (res.state == 0) {
          this.programList = [];
          //                    this.screenList = [];
          let data = [];
          res.value.forEach((item, index) => {
            data.push({
              label: item.programName,
              key: item.programId,
              operationId: item.programId,
            });
            this.programList = data;
          });
          return data;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 已添加节目接口
    getShowProgramList(data) {
      this.$axios
        .get("/acb/2.0/ydpScreen/queryProgram", {
          data: {
            screenId: this.screenId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            //                    this.selectValue = res.value.ydpProgramId
            if (res.value) {
              this.selectValue = [];
              this.selectValue.push(res.value.ydpProgramId);
            } else {
              this.selectValue = [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    submit() {
      if (!this.selectValue.length || this.selectValue.length > 1) {
        this.$alert("请选择一个节目且最多一个", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let opt = {
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        url: "/acb/2.0/ydpScreen/addProgram?programId=" + this.selectValue.join(","),
        //                            data: this.screenList,
        data: this.screenList,
        success: (res) => {
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.dialogVisible = false;
              //                                    this.screenList = []
              this.$refs.multipleTable.clearSelection();
              this.multipleSelection = [];
              this.getScreenList();
            });
          }
        },
      };
      this.$request(opt);
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.getScreenList();
    },
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.getScreenList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
    .searchWrapper
        padding 22px 22px 0
        background #ffffff
    .pagerWrapper
        text-align right
        font-size 12px
        background #ffffff
        margin-top 0
        .export
            font-size 12px
            .iconfont
                margin-right 0
    .el-button--success
      color: #fff !important;
      background-color: #67c23a !important;
      border-color: #67c23a !important;
    .el-button--danger
      color: #fff !important;
      background-color: #f56c6c !important;
      border-color: #f56c6c !important;
</style>
